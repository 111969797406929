import router from '../util/router'
import firebase from 'firebase/app'
require('firebase/auth');

export default {
  login({ commit }, accessToken) {
    commit('SET_ACCESS_TOKEN', accessToken)
  },
  logout({ commit }) {
    commit('CLEAR_ALL')
  },
  setPageTitle({ commit }, pageTitle) {
    commit('SET_PAGE_TITLE', pageTitle)
  },
  setCurrentRouteName({ commit }, currentRouteName) {
    commit('SET_CURRENT_ROUTE_NAME', currentRouteName)
  },
  userJoin({ commit }, { email, password }) {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        if(user) {
          user.user.sendEmailVerification().then(function() {
            // Email sent.
            // console.log("Email send successfully")
          }).catch(function(error) {
            // An error happened.
            // eslint-disable-next-line no-console
            console.log("Email sending error:", error)
          });
        }
        commit('SET_USER', user)
        commit('SET_IS_AUTHENTICATED', true)
        router.push('/home')
      }).catch( data => {
        // console.log("data in signup : ", data)
        commit('SET_LOGIN_FAILED_DATA', data)
        commit('SET_USER', null)
        commit('SET_IS_AUTHENTICATED', false)
      });

  },
  userLogin({ commit }, { email, password }) {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(data => {
        if( data.user.emailVerified == true) {
          commit('SET_ACCESS_TOKEN', true)
          commit('SET_USER', data.user)
          commit('SET_IS_AUTHENTICATED', true)
          commit('SET_USER_TOKEN', data.user.refreshToken)
          router.push('/home')
        } else {
          commit('SET_LOGIN_FAILED_DATA', {code: "emailVerified/false"})
        }
      })
      .catch((data) => {
        commit('SET_LOGIN_FAILED_DATA', data)
        commit('SET_USER', null);
        commit('SET_IS_AUTHENTICATED', false);
      });
  },
  userLoginWithGoogle({commit}, data ) {
    if( data.user.emailVerified == true) {
      commit('SET_ACCESS_TOKEN', true)
      commit('SET_USER', data.user)
      commit('SET_IS_AUTHENTICATED', true)
      commit('SET_USER_TOKEN', data.user.refreshToken)
      router.push('/home')
    } else {
      commit('SET_LOGIN_FAILED_DATA', {code: "emailVerified/false"})
    }
  },
  userSignOut({ commit }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        commit('SET_USER', null)
        commit('SET_IS_AUTHENTICATED', false)
        router.push('/');
      })
      .catch(() => {
        commit('SET_USER', null)
        commit('SET_IS_AUTHENTICATED', false)
        router.push('/')
      });
  },
  setDrawer({ commit }, drawer) {
    commit('SET_DRAWER', drawer)
  }
}
