<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>News List</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-dialog max-width="700px">
        <!--eslint-disable-next-line vue/no-unused-vars-->
        <template v-slot:activator="{ on }">
           <v-btn
            color="primary"
            @click.native="changeRoute({name: 'New News'})"
          >
            <v-icon small class="mr-2">add</v-icon>Create
          </v-btn>
        </template>
        
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="news" class="elevation-1">
      <template v-slot:items="props">
        <td>{{ props.item.author }}</td>
        <td class="text-xs-left">{{ props.item.title }}</td>
        <td class="text-xs-left">{{ props.item.date }}</td>
        <td class="text-xs-left">{{ props.item.status }}</td>
        <td class="justify-center layout px-0">
          <v-btn
            color="primary"
            @click.native="changeRoute({name: 'News Edit', params: {id: props.item.id, news:props.item}})"
          >
            <v-icon small class="mr-2">edit</v-icon>Edit
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import "firebase/storage";
import moment from 'moment'
import firebase from "firebase/app";

export default {
  name: "NewsList",
  components: {
  },
  mounted() {
    // const currentUser = this.$store.state.user;

    var ref = firebase.database().ref("/news/");
    ref.once("value", snapshot => {
      snapshot.forEach(childSnapshot => {
        var value = childSnapshot.val();
        this.news.push({
          id: value["id"],
          author: value["author"],
          content: value["content"],
          title: value["title"],
          coverImageURL: value["coverImageURL"],
          thumbnailImageURL: value["thumbnailImageURL"],
          videoURL: value["videoURL"] || "",
          status: value["status"],
          createdTimestamp: value["createdTimestamp"],
          createdDate: moment.unix(value["createdTimestamp"]/1000).format("YYYY-MM-DD"),
          date: moment.unix(value["createdTimestamp"]/1000).format("MMM DD"),
          modifiedTimestamp: value["modifiedTimestamp"],
          galleryId: value["galleryId"] || "",
          galleryNumberOfImages: value["galleryNumberOfImages"] || "",
        });

        this.news = this.news.reverse();
      });
    });
  },
  data: () => ({
    news: [],
    headers: [
      {
        text: "Author",
        align: "left",
        value: "author"
      },
      { text: "Title", value: "title" },
      { text: "Date", value: "date" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action", sortable: false }
    ],
  }),

  computed: {
  },

  methods: {
    changeRoute(r) {
      // console.log("new router: ", r);
      this.$router.push(r);
    }
  }
};
</script>

<style scoped>
</style>
