<template>
  <div>
    <v-toolbar color="white">
      <v-toolbar-title>Edit News</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <span v-if="!responsive" mobile-break-point="991">
        <v-dialog v-model="dialog" persistent max-width="290">
          <!--eslint-disable-next-line vue/no-unused-vars-->
          <template v-slot:activator="{ on }">
            <v-btn color="primary" @click="save" class="xs2 sm2 md2">
              <v-icon>save</v-icon> Save
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">News saving ...</v-card-title>
            <v-card-text>Please wait while saving this news.</v-card-text>
          </v-card>
        </v-dialog>
        <!-- <v-btn color="primary" class="xs2 sm2 md2"
         @click.native="changeRoute({name: 'news-preview', params: {id: currentNews.id, news:currentNews}})"
        >
          <v-icon left>remove_red_eye</v-icon>Preview
        </v-btn> -->
        <v-btn color="primary" @click="publish" class="xs2 sm2 md2">
          <span v-if="currentNews.status === 'unpublished'"><v-icon>publish</v-icon> Publish</span>
          <span v-else> <v-icon>get_app</v-icon> Unpublish</span>
        </v-btn>
      </span>
      <v-menu bottom left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
<!--        <v-list>-->
<!--          <v-list-tile>-->
<!--            <v-list-tile-action>-->
<!--              <v-icon>delete</v-icon>-->
<!--            </v-list-tile-action>-->
<!--            <v-list-tile-content>-->
<!--              <v-list-tile-title>Delete</v-list-tile-title>-->
<!--            </v-list-tile-content>-->
<!--          </v-list-tile>-->
<!--        </v-list>-->
      </v-menu>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-left="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="currentNews.createdDate"
                    label="Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="currentNews.createdDate"
                @input="datePickerMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentNews.title" label="Title" prepend-icon="title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentNews.author" label="Author Name" prepend-icon="person"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 class="with-video-label">
              <v-text-field v-model="currentNews.videoURL" label="Vimeo URL/Youtube Video ID" prepend-icon="url"></v-text-field>
            </v-flex>
            <v-flex xs6 sm6 md6>
              <v-icon>add_a_photo</v-icon>
              <span>Cover Image</span>
              <image-uploader
                id="cover"
                :news="this.currentNews"
                v-on:coverImageURL="getCoverImageURL"
                v-on:coverImageToUpload="getCoverImageToUpload"
                :coverImageURL="this.currentNews.coverImageURL"
              ></image-uploader>
            </v-flex>
            <v-flex xs6 sm6 md6>
              <v-icon>add_a_photo</v-icon>
              <span>Thumbnail Image</span>
              <image-uploader
                id="thumbnail"
                :news="this.currentNews"
                v-on:coverImageURL="getThumbnailImageURL"
                v-on:coverImageToUpload="getThumbnailImageToUpload"
                :thumbnailImageURL="this.currentNews.thumbnailImageURL"
              ></image-uploader>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea name="Content" v-model="currentNews.content" label="Content" auto-grow prepend-icon="line_style">
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-snackbar
        v-model="hasSaved"
        :timeout="2000"
        absolute
        top
        center
        color="success"
      >News Saved.</v-snackbar>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import firebase from "firebase/app";
import "firebase/storage";
import ImageUploader from "../core/ImageUploader";

export default {
  name: "NewsEdit",
  components: {
    ImageUploader,
  },
  props: ["news"],
  mounted() {
    if (this.$props.news) {
      this.currentNews = this.$props.news;
      this.currentNews.createdDate = moment.unix(this.currentNews.createdTimestamp/1000).format("YYYY-MM-DD");
      // console.log("this.currentNews: ", this.currentNews);
    } else {
      let id = this.$router.history.current.params.id;
      let ref = firebase.database().ref("/news/" + id);
      ref.once("value", snapshot => {
        var value = snapshot.val();
        this.currentNews = {
          id: value["id"],
          author: value["author"],
          content: value["content"],
          title: value["title"],
          coverImageURL: value["coverImageURL"],
          thumbnailImageURL: value["thumbnailImageURL"],
          vidoeURL: value["videoURL"] || "",
          status: value["status"],
          createdTimestamp: value["createdTimestamp"],
          createdDate: moment.unix(value["createdTimestamp"]/1000).format("YYYY-MM-DD"),
          modifiedTimestamp: value["modifiedTimestamp"],
          galleryId: value["galleryId"] || "",
          galleryNumberOfImages: value["galleryNumberOfImages"] || ""
        };

      });


    }

    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  data: () => ({
    date: moment().toISOString().substr(0, 10),
    // date: moment().toISOString().substr(0, 10),
    datePickerMenu: false,
    currentNews: {},
    dialog: false,
    coverImageToUpload: null,
    thumbnailImageToUpload: null,
    videoToUpload: null,
    responsive: false,
    uploading: false,
    progressUpload: 0,
    coverImageUploadEnd: false,
    thumbnailImageUploadEnd: false,
    videoUploadEnd: false,
    coverImageUploadTask: "",
    thumbnailImageUploadTask: "",
    videoUploadTask: "",
    updates: {},
    isPubished: false,
    dataSavingFinished: true,
    hasSaved: false,
  }),

  methods: {
    updateNews(coverImageURL, thumbnailImageURL) {

      let thumbnailUploaded = true;
      let coverUploaded = true;

      if(this.thumbnailImageToUpload) {
        thumbnailUploaded = false;
      }

      if(this.coverImageToUpload) {
        coverUploaded = false;
      }

      if( this.thumbnailImageToUpload && this.thumbnailImageUploadEnd) {
        thumbnailUploaded = true;
        this.currentNews.thumbnailImageURL = thumbnailImageURL;
      }

      if( this.coverImageToUpload && this.coverImageUploadEnd) {
        coverUploaded = true;
        this.currentNews.coverImageURL = coverImageURL;
      }

      // console.log("this.currentNews: ", this.currentNews);

      if(coverUploaded && thumbnailUploaded){

        this.currentNews.createdTimestamp = moment(this.currentNews.createdDate).format("x");
        this.currentNews.modifiedTimestamp = moment().tz("Australia/Sydney").format("x");
        this.currentNews.slug = this.slugify(this.currentNews.title)

        // Write the new news's data simultaneously in the newss list
        this.updates["/news/" + this.currentNews.id] = this.currentNews;
        firebase
          .database()
          .ref()
          .update(this.updates);

          this.hasSaved = true;
          this.dialog = false;
      }
    },
    uploadCoverImage(coverImage) {
      this.uploading = true;
      this.coverImageUploadTask = firebase
        .storage()
        .ref(`images/news/${this.currentNews.id}/cover/${this.currentNews.id}`)
        .put(coverImage);
    },
    uploadThumbnailImage(thumbnailImage) {

      // console.log("ThumbnailImage: ", thumbnailImage);
      this.uploading = true;

      this.thumbnailImageUploadTask = firebase
        .storage()
        .ref(`images/news/${this.currentNews.id}/thumbnail/${this.currentNews.id}`)
        .put(thumbnailImage);
    },
    uploadVideo(video) {
      // eslint-disable-next-line no-debugger
      debugger;

      // console.log("video: ", video);
      this.uploading = true;

      this.videoUploadTask = firebase
        .storage()
        .ref(`images/news/${this.currentNews.id}/video/${this.currentNews.id}`)
        .put(video);
    },
    // From: https://gist.github.com/mathewbyrne/1280286
    slugify(text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    },
    save() {
      this.dialog = true;
      var isAllEmpty =
        this.currentNews.title == "" &&
        this.currentNews.author == "" &&
        this.currentNews.content == "";

      if (!isAllEmpty) {
        // saving image with id of new news's id
        // console.log("this.coverImageToUpload:", this.coverImageToUpload);
         // saving image with id of new news's id
          if (this.coverImageToUpload || this.thumbnailImageToUpload || this.videoToUpload) {

            // create new news after uploading image
            if(this.coverImageToUpload) {
              this.uploadCoverImage(this.coverImageToUpload);
            }
            if(this.thumbnailImageToUpload){
              this.uploadThumbnailImage(this.thumbnailImageToUpload)
            }

        } else {
          this.updateNews();
        }

      }
    },

    publish() {
      this.currentNews.status = this.currentNews.status === 'published' ? "unpublished" : "published"
      this.save();
    },

    getCoverImageURL(v) {
      // console.log("getCoverImageURL: ", v);
      this.currentNews.coverImageURL = v;
    },

    getVideoURL(v) {
      // console.log("getCoverImageURL: ", v);
      this.currentNews.videoURL = v;
    },

    getCoverImageToUpload(v) {
      // console.log("Getting image to upload....");
      // this.currentNews.coverImageURL
      this.coverImageToUpload = v;
      // console.log("this.coverImageToUpload: ", this.coverImageToUpload);
    },

    getThumbnailImageURL(v) {
      // console.log("getCoverImageUrl: ", v);
      this.currentNews.thumbnailImageURL = v;
    },


    getThumbnailImageToUpload(v) {
      // console.log("getThumbnailImageToUpload: ", v);
      this.thumbnailImageToUpload = v;
    },

    getVideoToUpload(v) {
      // eslint-disable-next-line no-debugger
      debugger;
      // console.log("getThumbnailImageToUpload: ", v);
      this.videoToUpload = v;
    },

    // changeRoute(r) {
    //   // console.log("new router: ", r);
    //   this.$router.push(r);
    // },

    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  },

  watch: {
    coverImageUploadTask: function() {
      this.coverImageUploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.coverImageUploadTask.snapshot.ref.getDownloadURL().then(imageURL => {
            this.coverImageUploadEnd = true;
            this.updateNews(imageURL, this.currentNews.thumbnailImageURL);
          });
        }
      );
    },
    thumbnailImageUploadTask: function() {
      this.thumbnailImageUploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.thumbnailImageUploadTask.snapshot.ref.getDownloadURL().then(imageURL => {
            // console.log("thumbnail uploaded...", imageURL)
            this.thumbnailImageUploadEnd = true;
            this.updateNews(this.currentNews.coverImageURL, imageURL);
          });
        }
      );
    },
  },
};
</script>

<style scoped>
</style>
