<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-list>
      <v-layout class="fill-height" tag="v-list" column>
        <v-list-tile avatar>
          <v-list-tile-avatar color="white">
            <v-img :src="logo" height="34" contain/>
          </v-list-tile-avatar>
          <v-list-tile-title class="title">Admin</v-list-tile-title>
        </v-list-tile>
        <v-divider/>
        <!-- List of news -->
         <v-list-tile active-class="primary" class="v-list-item" to="/home">
          <v-list-tile-action>
            <i class="material-icons">dashboard</i>
          </v-list-tile-action>
          <v-list-tile-title>Home</v-list-tile-title>
        </v-list-tile>
        <v-list-tile active-class="primary" class="v-list-item" to="/news">
          <v-list-tile-action>
            <i class="material-icons">event_note</i>
          </v-list-tile-action>
          <v-list-tile-title>News</v-list-tile-title>
        </v-list-tile>
        <v-list-tile active-class="primary" class="v-list-item" to="/positions">
          <v-list-tile-action>
            <i class="material-icons">people</i>
          </v-list-tile-action>
          <v-list-tile-title>Positions</v-list-tile-title>
        </v-list-tile>
        <v-list-tile active-class="primary" class="v-list-item" to="/logout">
          <v-list-tile-action>
            <i class="material-icons">exit_to_app</i>
          </v-list-tile-action>
          <v-list-tile-title>Logout</v-list-tile-title>
        </v-list-tile>
      </v-layout>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  data: () => ({
    color: "black",
    logo:
      "https://res.cloudinary.com/mirkco/image/upload/v1600427863/spaces-interactive/images/logos/Spaces_Black.png",
    links: [
      {
        to: "/dashboard",
        text: "Dashboard"
      },
      {
        to: "/news",
        text: "News List"
      }
    ],
    responsive: false
  }),
  computed: {
    inputValue: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.dispatch('setDrawer', val);
      }
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  }
};
</script>

<style>
#app-drawer {
}
</style>
