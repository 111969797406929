<template>
  <div>
    <v-toolbar color="white">
      <v-toolbar-title>New News</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <span v-if="!responsive" mobile-break-point="1065">
        <v-dialog v-model="dialog" persistent max-width="290">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" @click="save" class="xs2 sm2 md2">
              <v-icon>save</v-icon>Save
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">News saving ...</v-card-title>
            <v-card-text>{{newsSavingMessage}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!showDialogCloseBtn" color="green darken-1" flat @click="dialog = false; showDialogCloseBtn = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-btn color="primary" class="xs2 sm2 md2">
          <v-icon left>remove_red_eye</v-icon>Preview
        </v-btn>
        <v-btn color="primary" class="xs2 sm2 md2">
          <v-icon left>publish</v-icon>Publish
        </v-btn>-->
      </span>
      <v-menu bottom left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>delete</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Delete</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newsCreatedDate"
                    label="Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="datePickerMenu = false"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentNews.title" label="Title" prepend-icon="title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentNews.author" label="Author Name" prepend-icon="person"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 class="with-video-label">
              <v-text-field v-model="currentNews.videoURL" label="Vimeo URL/Youtube Video ID" prepend-icon="link"></v-text-field>
            </v-flex>
            <v-flex xs6 sm6 md6>
              <v-icon>add_a_photo</v-icon>
              <span>Cover Image</span>
              <image-uploader
                id="cover"
                :news="this.currentNews"
                v-on:coverImageURL="getCoverImageURL"
                v-on:coverImageToUpload="getCoverImageToUpload"
                :coverImageURL="this.currentNews.coverImageURL"
              ></image-uploader>
            </v-flex>
            <v-flex xs6 sm6 md6>
              <v-icon>add_a_photo</v-icon>
              <span>Thumbnail Image</span>
              <image-uploader
                id="thumbnail"
                :news="this.currentNews"
                v-on:coverImageURL="getThumbnailImageURL"
                v-on:coverImageToUpload="getThumbnailImageToUpload"
                :thumbnailImageURL="this.currentNews.thumbnailImageURL"
              ></image-uploader>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea name="Content" v-model="currentNews.content" label="Content" auto-grow prepend-icon="line_style">
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <!-- <v-snackbar
        v-model="hasSaved"
        :timeout="2000"
        absolute
        top
        left
        color="success"
      >News has been saved</v-snackbar> -->
    </v-card>
  </div>
</template>

<script>
import "firebase/storage";
import moment from "moment";
import firebase from "firebase/app";
import router from "../../util/router";
import ImageUploader from "../core/ImageUploader";

export default {
  name: "NewsList",
  components: {
    ImageUploader
  },
  mounted() {
    var ref = firebase.database().ref("/news/");
    ref.once("value", snapshot => {
      snapshot.forEach(childSnapshot => {
        var value = childSnapshot.val();
        this.news.push({
          id: value["id"],
          author: value["author"],
          content: value["content"],
          title: value["title"],
          coverImageURL: value["coverImageURL"],
          status: value["status"],
          thumbnailImageURL: value["thumbnailImageURL"],
          createdTimestamp: value["createdTimestamp"],
          modifiedTimestamp: value["modifiedTimestamp"]
        });

        this.news = this.news.reverse();
      });
    });
  },
  data: () => ({
    date: moment().toISOString().substr(0, 10),
    datePickerMenu: false,
    newsSavingMessage: "",
    responsive: false,
    news: [],
    deleteEnable: true,
    dialog: false,
    editedIndex: -1,
    currentNews: {
      id: "",
      title: "",
      author: "",
      coverImageURL: "",
      thumbnailImageURL: "",
      content: "",
      slug:"",
      status: "unpublished",
      createdTimestamp: "",
      modifiedTimestamp: "",
      videoURL: '',
    },
    coverImageToUpload: null,
    thumbnailImageToUpload: null,
    progressUpload: 0,
    coverImageUploadEnd: false,
    thumbnailImageUploadEnd: false,
    coverImageUploadTask: "",
    thumbnailImageUploadTask: "",
    updates: {},
    showDialogCloseBtn: false,
    // hasSaved: false
  }),

  computed: {
    newsCreatedDate() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentNews.createdTimestamp = moment(this.date).format("x");
      // console.log("this.currentNews.createdTimestamp : ", this.currentNews.createdTimestamp);
      return this.date ? moment(this.date).format("YYYY-MM-DD") : "";
    }
  },

  watch: {
    coverImageUploadTask: function() {
      this.coverImageUploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.coverImageUploadTask.snapshot.ref
            .getDownloadURL()
            .then(imageURL => {
              this.coverImageUploadEnd = true;
              this.createNews(imageURL, this.currentNews.thumbnailImageURL);
            });
        }
      );
    },
    thumbnailImageUploadTask: function() {
      this.thumbnailImageUploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.thumbnailImageUploadTask.snapshot.ref
            .getDownloadURL()
            .then(imageURL => {
              this.thumbnailImageUploadEnd = true;
              this.createNews(this.currentNews.coverImageURL, imageURL);
            });
        }
      );
    }
  },

  methods: {
    createNews(coverImageURL, thumbnailImageURL) {

      // If images is selected, need to upload these into firebase before saving news (To get the image's url for news)
      let thumbnailUploaded = true;
      let coverUploaded = true;

      // Setting image uploaded to false if image is selected
      if (this.thumbnailImageToUpload) {
        thumbnailUploaded = false;
      }
      if (this.coverImageToUpload) {
        coverUploaded = false;
      }

      if (this.thumbnailImageToUpload && this.thumbnailImageUploadEnd) {
        thumbnailUploaded = true;
        this.currentNews.thumbnailImageURL = thumbnailImageURL;
      }

      if (this.coverImageToUpload && this.coverImageUploadEnd) {
        coverUploaded = true;
        this.currentNews.coverImageURL = coverImageURL;
      }

      // console.log("this.currentNews: ", this.currentNews);

      if (coverUploaded && thumbnailUploaded) {
        // console.log("Creating news...");
        this.currentNews.slug = this.slugify(this.currentNews.title)
        // Write the new news's data simultaneously in the newss list
        this.updates["/news/" + this.currentNews.id] = this.currentNews;
        firebase
          .database()
          .ref()
          .update(this.updates);

        this.news.push(this.currentNews);
        router.push({
          name: "News Edit",
          params: { id: this.currentNews.id, news: this.currentNews }
        });
      }
    },

    uploadCoverImage(coverImage) {
      // console.log("CoverImage: ", coverImage);
      this.coverImageUploadTask = firebase
        .storage()
        .ref(`images/news/${this.currentNews.id}/cover/${this.currentNews.id}`)
        .put(coverImage);
    },

    uploadThumbnailImage(thumbnailImage) {
      // console.log("ThumbnailImage: ", thumbnailImage);
      this.thumbnailImageUploadTask = firebase
        .storage()
        .ref(
          `images/news/${this.currentNews.id}/thumbnail/${this.currentNews.id}`
        )
        .put(thumbnailImage);
    },

    // From: https://gist.github.com/mathewbyrne/1280286
    slugify(text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    },

    save() {
      var isAllEmpty =
        this.currentNews.title == "" &&
        this.currentNews.author == "" &&
        this.currentNews.content == "";

      if (!isAllEmpty) {
        this.newsSavingMessage = "Please wait while saving this news.";

        // console.log("Creating new news into firebase ... ");

        // Creating new News into Firebase
        this.currentNews.id = firebase
          .database()
          .ref()
          .child("news")
          .push().key;

        var currentTimestamp = moment().tz("Australia/Sydney").format("x");
        this.currentNews.modifiedTimestamp = currentTimestamp;

        // saving image with id of new news's id
        if (this.coverImageToUpload || this.thumbnailImageToUpload) {

          // create new news after uploading images
          if (this.coverImageToUpload) {
            this.uploadCoverImage(this.coverImageToUpload);
          }
          if (this.thumbnailImageToUpload) {
            this.uploadThumbnailImage(this.thumbnailImageToUpload);
          }
        } else {
          // create new news without images
          this.createNews("");
          router.push({
            name: "News Edit",
            params: { id: this.currentNews.id, news: this.currentNews }
          });
        }
      } else {
        this.showDialogCloseBtn = true;
        this.newsSavingMessage = "Please fill up all the required fields.";
      }
    },

    getCoverImageURL(v) {
      // console.log("getCoverImageURL: ", v);
      this.currentNews.coverImageURL = v;
    },

    getCoverImageToUpload(v) {
      // console.log("getCoverImageToUpload: ", v);
      this.coverImageToUpload = v;
    },

    getThumbnailImageURL(v) {
      // console.log("getThumbnailImageURL: ", v);
      this.currentNews.thumbnailImageURL = v;
    },

    getThumbnailImageToUpload(v) {
      // console.log("getThumbnailImageToUpload: ", v);
      this.thumbnailImageToUpload = v;
    },
  }
};
</script>

<style>

</style>
