import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../vuex/store'

/* PAGES */
import Home from '../components/pages/Dashboard'
import Login from '../components/pages/Login'
// import SignUp from '../components/pages/SignUp'
import NewsList from '../components/pages/NewsList'
import NewsEdit from '../components/pages/NewsEdit'
import NewNews from '../components/pages/NewsCreate'
import PositionsList from "../components/pages/PositionsList";
import PositionCreate from "../components/pages/PositionCreate";
import PositionEdit from "../components/pages/PositionEdit";
// import NewsPreview from '../components/pages/NewsPreview'

Vue.use(VueRouter)

/**
 * VueRouter Config - Defines our paths and the Custom components for each path
 * Note: The router mounts into the App component (see components/App.vue)
 */

const router = new VueRouter({
  routes: [
    // Login Route - The Login Form
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Login
    },
    // {
    //   path: '/signup',
    //   name: 'sign-up',
    //   component: SignUp
    // },
    // Home Route - The App Dashboard
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/news',
      name: 'News List',
      component: NewsList
    },
    {
      path: '/newnews',
      name: 'New News',
      component: NewNews
    },
    {
      path: '/newsedit/:id',
      name: 'News Edit',
      component: NewsEdit,
      props: (route) => ({
        ...route.params
      })
    },
    {
      path: '/positions',
      name: 'Positions List',
      component: PositionsList
    },
    {
      path: '/new-position',
      name: 'New Position',
      component: PositionCreate
    },
    {
      path: '/edit-position/:id',
      name: 'Position Edit',
      component: PositionEdit,
      props: (route) => ({
        ...route.params
      })
    },
    // {
    //   path: '/newspreview/:id',
    //   name: 'Preview News',
    //   component: NewsPreview,
    //   props: (route) => ({
    //     ...route.params
    //   })
    // },
    {
      path: '*',
      redirect: '/home'
    }
  ]
})

// Guard the routes by checking for an existing access token
router.beforeEach((to, from, next) => {
  // If the access token exists, let the user access anywhere but the login route

  if (store.state.accessToken !== false) {
    if (to.name === 'logout') {
      store.dispatch('logout')
      next('/login')
    }
    if (to.name === 'login') {
      next('/home')
    } else {
      next()
    }

  }
  else if ( to.name === 'sign-up') {
    next()
  }
  else {
    // store.dispatch('userSignOut')
    store.dispatch('logout')
    // If the access token does not exist, force the user to the login route
    if (to.name !== 'login') {
      next('/')
    } else {
      next()
    }
  }
})

// Update the current route name in vuex state each time the route changes
// This is used for navbar active states
router.afterEach((to) => {
  store.dispatch('setCurrentRouteName', to.name)
})

export default router

