/* eslint camelcase: 0 */
export default {
  defaultCode: 'en-GB',
  languages: [
    {
      name: 'English',
      code: 'en-GB',
      urlPrefix: 'en',
      translationKey: 'en'
    }
  ],
  translations: {
    en: {
      site_title: 'Admin Site',
      signup_page: 'Sign Up',
      login_page: 'Login'
    }
  }
};
