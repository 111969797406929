<template>
  <v-app>
    <!-- <sign-up v-if="isSignUpPage"/> -->
    <!-- <login v-else-if="!isLoggedIn"/> -->
    <login v-if="!isLoggedIn"/>
    <div v-else>
      <toolbar/>
      <core-drawer/>
      <core-view/>
    </div>
  </v-app>
</template>

<script>
import Login from "./components/pages/Login";
import CoreDrawer from "./components/core/Drawer";
import CoreView from "./components/core/View";
import Toolbar from "./components/core/Toolbar";

export default {
  name: "App",
  components: {
    Login,
    CoreDrawer,
    CoreView,
    Toolbar
  },
  data() {
    return {};
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.state.accessToken;
    },
    isSignUpPage() {
      return this.$store.state.currentRouteName === "sign-up";
    }
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  }
};
</script>

<style>
</style>
