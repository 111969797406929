import Vue from 'vue'
import initialState from './state'

export default {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
  },
  SET_PAGE_TITLE(state, pageTitle) {
    state.pageTitle = pageTitle
    if (pageTitle === '') {
      document.title = 'Mirk Admin'
    } else {
      document.title = pageTitle + ' - Mirk Admin'
    }
  },
  SET_CURRENT_ROUTE_NAME(state, currentRouteName) {
    state.currentRouteName = currentRouteName
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_IS_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  SET_USER_TOKEN(state, userToken) {
    state.userToken = userToken
  },
  SET_DRAWER(state, drawer) {
    state.drawer = drawer
  },
  SET_LOGIN_FAILED_DATA(state, data) {
    state.loginData = data
  },
  CLEAR_ALL(state) {
    for (let key in initialState) {
      Vue.set(state, key, initialState[key])
    }
  },
}
