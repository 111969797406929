<template>
  <v-app id="login">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3 loginCard">
              <v-card-text>
                <div class="layout column align-center">
                  <div class="logoDiv">
                    <img src="https://storage.spacesinteractive.co/images/logos/SI-black.png" alt="logo" >
                  </div>
                  <h1 class="welcomeText">{{$t('login_page')}}</h1>
                </div>
                <div id="firebaseui-auth-container">
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
// eslint-disable-next-line
import firebaseUI from 'firebaseui'
export default {
  name: "Login",
  data() {
    return {
      valid: false,
      email: "",
      password: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid",
        v =>
          /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(spacesinteractive)\.co$/.test(
            v
          ) || "E-mail must be a Spaces Interactive email"
      ],
      passwordRules: [v => !!v || "Password is required"],
      showPassword: false,
      ui: {},
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("userLogin", {
          email: this.email,
          password: this.password
        });
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    this.ui.start('#firebaseui-auth-container', {
      signInSuccessUrl: '/',
      signInOptions: [
        {
          // List of OAuth providers supported.
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: { hd: 'spacesinteractive.co' },
        }
      ],
      'callbacks': {
        // Called when the user has been successfully signed in.
        // eslint-disable-next-line no-unused-vars
        'signInSuccessWithAuthResult': (authResult, redirectUrl) => {
          if (authResult.user) {
            this.$store.dispatch("userLoginWithGoogle", authResult);
          }
          // Do not redirect.
          return false;
        }
      },
    });

    this.ui.disableAutoSignIn();

  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    loginErrorMessage() {
      const errorCode = this.$store.state.loginData.code
      if(errorCode === "auth/wrong-password") {
        return "Email address or password is invalid. Please enter correct email address and password."
      }
      if(errorCode === "emailVerified/false") {
        return "Your email is not verified yet. Please check your email and follow the instruction to verify.";
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/settings.less";

.loginCard {
  border-radius: 19px;
}
.logoDiv {
  width: 65%;
  margin-bottom: 11.4%;
  margin-left: auto;
  margin-right: auto;
}
.logoDiv img {
  width: 100%;
}
.welcomeText {
  font-size: 25px;
  margin: auto;
  margin-bottom: 25px;
}

#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background-color: #424242;
}

.primary{
  background-color: @primary !important;
  border-color: @primary !important;
}
</style>
