<template>
  <div>
    <v-toolbar color="white">
      <v-toolbar-title>Edit Position</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <span v-if="!responsive" mobile-break-point="991">
        <v-dialog v-model="dialog" persistent max-width="290">
          <!--eslint-disable-next-line vue/no-unused-vars-->
          <template v-slot:activator="{ on }">
            <v-btn color="primary" @click="save" class="xs2 sm2 md2">
              <v-icon>save</v-icon> Save As Draft
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">News saving ...</v-card-title>
            <v-card-text>Please wait while saving this news.</v-card-text>
          </v-card>
        </v-dialog>
        <v-btn color="primary" @click="publish" class="xs2 sm2 md2">
          <span v-if="currentPosition.status === 'unpublished'"><v-icon>publish</v-icon> Publish</span>
          <span v-else> <v-icon>get_app</v-icon> Unpublish</span>
        </v-btn>
      </span>
      <v-menu bottom left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
      </v-menu>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-menu
                  v-model="datePickerMenu"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="currentPosition.createdDate"
                      label="Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="currentPosition.createdDate"
                    @input="datePickerMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.author" label="Author Name" prepend-icon="person"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.email" label="Email to send CVs" prepend-icon="email"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.position_title" label="Position title" prepend-icon="title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.position_type" label="Position type" prepend-icon="title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea name="position_description" v-model="currentPosition.position_description" label="Position Description" auto-grow prepend-icon="line_style">
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-snackbar
        v-model="hasSaved"
        :timeout="2000"
        absolute
        top
        center
        color="success"
      >Position Saved.</v-snackbar>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import firebase from "firebase/app";
import "firebase/storage";

export default {
  name: "PositionEdit",
  components: {},
  props: ["position"],
  mounted() {
    if (this.$props.position) {
      this.currentPosition = this.$props.position;
      this.currentPosition.createdDate = moment.unix(this.currentPosition.createdTimestamp/1000).format("YYYY-MM-DD")
      // console.log("this.currentPosition: ", this.$props.position)
    } else {
      let id = this.$router.history.current.params.id;
      let ref = firebase.database().ref("/positions/" + id);
      ref.once("value", snapshot => {
        var value = snapshot.val();
        this.currentPosition = {
          id: value["id"],
          author: value["author"],
          email: value["email"],
          position_description: value["position_description"],
          position_title: value["position_title"],
          position_type: value["position_type"],
          status: value["status"],
          createdTimestamp: value["createdTimestamp"],
          createdDate: moment.unix(value["createdTimestamp"]/1000).format("YYYY-MM-DD"),
          modifiedTimestamp: value["modifiedTimestamp"],
        };

      });


    }

    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  data: () => ({
    date: moment().toISOString().substr(0, 10),
    // date: moment().toISOString().substr(0, 10),
    datePickerMenu: false,
    currentPosition: {},
    dialog: false,
    coverImageToUpload: null,
    thumbnailImageToUpload: null,
    responsive: false,
    uploading: false,
    progressUpload: 0,
    coverImageUploadEnd: false,
    thumbnailImageUploadEnd: false,
    coverImageUploadTask: "",
    thumbnailImageUploadTask: "",
    updates: {},
    isPubished: false,
    dataSavingFinished: true,
    hasSaved: false,
  }),

  methods: {
    save() {

      this.dialog = true;

      var isAllEmpty =
        this.currentPosition.position_title == "" &&
        this.currentPosition.author == "" &&
        this.currentPosition.email == "" &&
        this.currentPosition.position_type == "" &&
        this.currentPosition.position_description == "";

      if (!isAllEmpty) {
        this.currentPosition.createdTimestamp = moment(this.currentPosition.createdDate).format("x");
        this.currentPosition.modifiedTimestamp = moment().tz("Australia/Sydney").format("x");

        // Write the new news's data simultaneously in the newss list
        this.updates["/positions/" + this.currentPosition.id] = this.currentPosition;
        firebase
          .database()
          .ref()
          .update(this.updates);

        this.hasSaved = true;
        this.dialog = false;
      }
    },

    publish() {
      this.currentPosition.status = this.currentPosition.status === 'published' ? "unpublished" : "published"
      this.save();
    },

    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  }
}
</script>

<style scoped>
</style>
