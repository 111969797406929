<template>
  <v-layout>
    <v-flex xs12 sm12 md12 >
      <v-card>
        <v-card-text primary-title justify-center>
          <div>
            <h3 class="headline mb-0" style="text-align:center">Welcome to Spaces Site Admin</h3>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Home",
  components: {
  },
  mounted() {}
};
</script>


<style lang="less">
@import "../../assets/less/settings.less";

.application--wrap{
  .primary{
    background-color: @primary !important;
    border-color: @primary !important;
  }

  .v-list__tile--avatar{
    padding-bottom: 16px;
  }
}

</style>
