import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import state from './state'
import mutations from './mutations'
import actions from './actions'

import users from './modules/users'


/**
 * Vuex Config - The App's shared state and state mutators
 */
export default new Vuex.Store({
  state: { ...state },
  mutations,
  actions,
  modules: {
    users,
  },
  plugins: [
    createPersistedState()
    // @TODO: re-enable the following inclusion-only paths if users get cache storage errors.
    /* createPersistedState({
      paths: [ // Only persist the following items
        'accessToken',
        'users.currentUser'
      ]
    }) */
  ]
})
