export default {
  // Access Token for accessing the API
  accessToken: false,
  pageTitle: '',
  currentRouteName: '',

  // User authentication
  user: null,
  isAuthenticated: false,
  userToken: '',
  drawer: true,
  loginData: '',
  
}
