<template>
  <div>
    <v-layout>
      <v-flex xs6 sm6 md6>
        <v-btn @click.native="selectFile">
          <span>
            Select an image
          </span>
        </v-btn>
      </v-flex>
    </v-layout>
    <input
      :id="this.$props.id"
      type="file"
      name="file"
      ref="uploadInput"
      accept="image/*"
      :multiple="false"
      @change="detectFiles($event)"
    >
    <img v-if="this.localImage != ''" :src="this.localImage" width="100%">
    <img v-else :src="this.$props.id === 'cover' ? news.coverImageURL : news.thumbnailImageURL" width="100%">
  </div>
</template>

<script>
import "firebase/storage";

export default {
  /* eslint-disable */
  props: {
    oldImgUrl: "",
    news: "",
    id: ""
  },
  data() {
    return {
      // progressUpload: 0,
      fileName: "",
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      downloadURL: "",
      fileList: [],
      responsive: false,
      localImage: "",
    };
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    detectFiles(e) {
      this.fileList = e.target.files || e.dataTransfer.files;
      this.localImage = URL.createObjectURL(this.fileList[0]);
      this.$emit("coverImageToUpload", this.fileList[0]);
    },
    // deleteImage() {
    //   console.log("this.oldImgUrl: ", this.$props.news.localImage);
    //   if (this.$props.news.localImage !== "") {
    //     this.deleteImgOnFirebase();
    //   }
    // },
    // setCoverImgOnUpdate() {
    //   this.uploadEnd = true;
    //   this.downloadURL = this.oldImgUrl;
    // },
    // deleteImgOnFirebase() {
    //   firebase
    //     .storage()
    //     .ref(`images/news/${this.$props.news.id}/${this.$props.news.id}`)
    //     .delete()
    //     .then(() => {
    //       this.uploading = false;
    //       this.uploadEnd = false;
    //       this.downloadURL = "";
    //       console.log("image deleted.");
    //     })
    //     .catch(error => {
    //       console.error(`file delete error occured: ${error}`);
    //     });
    // },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  },
  // watch: {
  //   uploadTask: function() {
  //     this.uploadTask.on(
  //       "state_changed",
  //       sp => {
  //         this.progressUpload = Math.floor(
  //           (sp.bytesTransferred / sp.totalBytes) * 100
  //         );
  //       },
  //       null,
  //       () => {
  //         this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
  //           this.uploadEnd = true;
  //           this.downloadURL = downloadURL;
  //           this.$emit("downloadURL", downloadURL);
  //         });
  //       }
  //     );
  //   }
  // },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  }
};
</script>

<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>