<template>
  <div>
    <v-toolbar color="white">
      <v-toolbar-title>New Position</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <span v-if="!responsive" mobile-break-point="1065">
        <v-dialog v-model="dialog" persistent max-width="290">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" @click="save" class="xs2 sm2 md2">
              <v-icon>save</v-icon>Save
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">Position saving ...</v-card-title>
            <v-card-text>{{positionSavingMessage}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!showDialogCloseBtn" color="green darken-1" flat @click="dialog = false; showDialogCloseBtn = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-btn color="primary" class="xs2 sm2 md2">
          <v-icon left>remove_red_eye</v-icon>Preview
        </v-btn>
        <v-btn color="primary" class="xs2 sm2 md2">
          <v-icon left>publish</v-icon>Publish
        </v-btn>-->
      </span>
      <v-menu bottom left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>delete</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Delete</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-menu
                v-model="datePickerMenu"
                :close-on-position_description-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="positionsCreatedDate"
                    label="Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="datePickerMenu = false"></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.author" label="Author Name" prepend-icon="person"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.email" label="Email to send CVs" prepend-icon="email"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.position_title" label="Position title" prepend-icon="title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="currentPosition.position_type" label="Position type" prepend-icon="title"></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea name="position_description" v-model="currentPosition.position_description" label="Position Description" auto-grow prepend-icon="line_style">
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import "firebase/storage";
import moment from "moment";
import firebase from "firebase/app";
import router from "../../util/router";

export default {
  name: "NewsList",
  components: {},
  mounted() {
    var ref = firebase.database().ref("/positions/");
    ref.once("value", snapshot => {
      snapshot.forEach(childSnapshot => {
        var value = childSnapshot.val();
        this.positions.push({
          id: value["id"],
          author: value["author"],
          email: value["email"],
          position_description: value["position_description"],
          position_title: value["position_title"],
          position_type: value["position_type"],
          status: value["status"],
          modifiedTimestamp: value["modifiedTimestamp"]
        });

        this.positions = this.positions.reverse();
      });
    });
  },
  data: () => ({
    date: moment().toISOString().substr(0, 10),
    datePickerMenu: false,
    positionSavingMessage: "",
    responsive: false,
    positions: [],
    deleteEnable: true,
    dialog: false,
    editedIndex: -1,
    currentPosition: {
      id: "",
      position_title: "",
      position_type: "",
      author: "",
      email: "",
      position_description: "",
      status: "unpublished",
      createdTimestamp: "",
      modifiedTimestamp: ""
    },
    coverImageToUpload: null,
    thumbnailImageToUpload: null,
    progressUpload: 0,
    coverImageUploadEnd: false,
    thumbnailImageUploadEnd: false,
    coverImageUploadTask: "",
    thumbnailImageUploadTask: "",
    updates: {},
    showDialogCloseBtn: false,
    // hasSaved: false
  }),

  computed: {
    positionsCreatedDate() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentPosition.createdTimestamp = moment(this.date).format("x");
      // console.log("this.currentPosition.createdTimestamp : ", this.currentPosition.createdTimestamp);
      return this.date ? moment(this.date).format("YYYY-MM-DD") : "";
    }
  },

  watch: {
  },

  methods: {
    save() {
      var isAllEmpty =
        this.currentPosition.position_title == "" &&
        this.currentPosition.author == "" &&
        this.currentPosition.email == "" &&
        this.currentPosition.position_type == "" &&
        this.currentPosition.position_description == "";

      if (!isAllEmpty) {
        this.positionSavingMessage = "Please wait while saving this position.";

        // console.log("Creating new position into firebase ... ", this.currentPosition);
        // generate the key
        this.currentPosition.id = firebase
          .database()
          .ref()
          .child("positions")
          .push().key;

        this.currentPosition.modifiedTimestamp = moment().tz("Australia/Sydney").format("x");

        // then update the ref with updatedData
        this.updates["/positions/" + this.currentPosition.id] = this.currentPosition;

        firebase
          .database()
          .ref()
          .update(this.updates);

        this.positions.push(this.currentPosition);
        router.push({
          name: "Position Edit",
          params: { id: this.currentPosition.id, position: this.currentPosition }
        });

      } else {
        this.showDialogCloseBtn = true;
        this.positionSavingMessage = "Please fill up all the required fields.";
      }
    },
  }
};
</script>

<style>

</style>
