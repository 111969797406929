// Firebase API Creds
export const FIREBASE_API_KEY = 'AIzaSyBXCbqN4g9MDUb7jz-p0_QCEA7skLSR6WQ'
export const AUTH_DOMAIN = 'admin.spacesinteractive.dev'
export const DATABASE_URL = 'https://spaces-interactive-staging-default-rtdb.firebaseio.com/'
export const PROJECT_ID = 'spaces-interactive-staging'
export const STORAGE_BUCKET = 'spaces-interactive.appspot.com'
export const GOOGLE_ANALYTICS_ID = 'UA-177207213-1'


export const staging = {
    apiKey: 'AIzaSyBXCbqN4g9MDUb7jz-p0_QCEA7skLSR6WQ',
    authDomain: 'admin.spacesinteractive.dev',
    databaseURL: 'https://spaces-interactive-staging-default-rtdb.firebaseio.com/',
    projectId: 'spaces-interactive-staging',
    storageBucket: 'spaces-interactive-staging.appspot.com'
}
export const prod = {
    apiKey: 'AIzaSyBPxis_mLPH2YtukEXa3bEz6N6bm-Mq8Ek',
    authDomain: 'admin.spacesinteractive.co',
    databaseURL: 'https://spaces-interactive.firebaseio.com/',
    projectId: 'spaces-interactive',
    storageBucket: 'spaces-interactive.appspot.com'
}
