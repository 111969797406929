import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'

import store from './vuex/store'
import router from './util/router'
import VeeValidate from 'vee-validate'
import VueI18nManager from 'vue-i18n-manager'
import langConfig from './lang/config'
import firebase from 'firebase/app'
import 'firebase/database'
import { staging, prod } from './constants'
import moment from 'moment-timezone'

Vue.config.productionTip = false
Vue.use(VeeValidate)

// language config
Vue.use(VueI18nManager, {
  store,
  router,
  config: langConfig,
})
Vue.initI18nManager()

// Initialize Firebase
const firebaseConfig = process.env.NODE_ENV === 'production' ? prod : staging;

firebase.initializeApp(firebaseConfig)

moment.tz.setDefault('Australia/Sydney')

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
